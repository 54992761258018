import React, { useState, useContext } from "react";
import { Route, Routes } from "react-router-dom";
import { ROUTE_CONSTANTS } from "./constants/routeConstants";
import {
  SendMoney,
  History,
  TransferProcessing,
  TransferSuccessful,
  TransferFailed,
  IncomingDetails,
  OutgoingDetails,
  Settings,
  SetDefaultCard,
  ScheduledPayments,
  ScheduledDetails,
  FundingiFrame,
  EventDemo,
  BannoPlugin,
} from "./screens";
import { TokenSignIn, NavBar, SignOut } from "./components";
import FiBranding from "./theme/branding";
import { DataContext } from "./dataContext";

const {
  BANNO_PLUGIN,
  SIGN_OUT,
  TOKEN_SIGN_IN,
  HISTORY_PAGE,
  OUTGOING_DETAILS,
  INCOMING_DETAILS,
  MAIN_PAGE,
  SETTINGS,
  SET_DEFAULT_ACCOUNT,
  TRANSFER_SUCCESSFUL,
  TRANSFER_FAILED,
  TRANSFER_PROCESSING,
  FUNDING,
  FUNDING_SIGN_IN,
  EVENT_DEMO,
  SCHEDULE_PAYMENT,
  SCHEDULED_PAYMENTS,
  SCHEDULED_DETAILS,
} = ROUTE_CONSTANTS;

const AppRouter = () => {
  const { branding, dropDownStyle, dropDownTheme } = FiBranding();
  const { loggedIn, fundingToken, p2pConfig } = useContext(DataContext);
  const [fromDemoPage, setFromDemoPage] = useState(false);

  let homePath = MAIN_PAGE;

  if (fromDemoPage) {
    homePath = EVENT_DEMO;
  }

  return (
    <>
      {loggedIn ? (
        <>
          <NavBar branding={branding} homePath={homePath} />
          <Routes>
            {[TOKEN_SIGN_IN, FUNDING_SIGN_IN].map((path) => (
              <Route path={path} key={path} element={<TokenSignIn />} />
            ))}
            <Route
              path={MAIN_PAGE}
              element={
                <SendMoney
                  dropDownStyle={dropDownStyle}
                  dropDownTheme={dropDownTheme}
                  setFromDemoPage={setFromDemoPage}
                  isScheduled={false}
                />
              }
            />
            {p2pConfig.scheduled_recurring_enabled && (
              <>
                <Route
                  path={SCHEDULE_PAYMENT}
                  element={
                    <SendMoney
                      dropDownStyle={dropDownStyle}
                      dropDownTheme={dropDownTheme}
                      setFromDemoPage={setFromDemoPage}
                      isScheduled={true}
                    />
                  }
                />
                <Route
                  path={SCHEDULED_PAYMENTS}
                  element={<ScheduledPayments />}
                />
                <Route
                  path={SCHEDULED_DETAILS}
                  element={<ScheduledDetails />}
                />
              </>
            )}
            <Route path={HISTORY_PAGE} element={<History />} />
            <Route
              path={TRANSFER_PROCESSING}
              element={
                <TransferProcessing branding={branding} homePath={homePath} />
              }
            />
            <Route
              path={TRANSFER_SUCCESSFUL}
              element={
                <TransferSuccessful branding={branding} homePath={homePath} />
              }
            />
            <Route
              path={TRANSFER_FAILED}
              element={<TransferFailed homePath={homePath} />}
            />
            <Route
              path={OUTGOING_DETAILS}
              element={
                <OutgoingDetails branding={branding} homePath={homePath} />
              }
            />
            <Route
              path={INCOMING_DETAILS}
              element={<IncomingDetails homePath={homePath} />}
            />
            <Route path={SETTINGS} element={<Settings />} />
            <Route path={SET_DEFAULT_ACCOUNT} element={<SetDefaultCard />} />
            {fundingToken && (
              <Route
                path={FUNDING}
                element={<FundingiFrame branding={branding} />}
              />
            )}
            {branding.event_demo && (
              <Route
                path={EVENT_DEMO}
                element={<EventDemo setFromDemoPage={setFromDemoPage} />}
              />
            )}
            <Route path={BANNO_PLUGIN} element={<BannoPlugin />} />
            <Route path={SIGN_OUT} element={<SignOut />} />
            <Route
              path="/*"
              element={<h3 className="center_page">Page Not Found</h3>}
            />
          </Routes>
        </>
      ) : (
        <Routes>
          {[TOKEN_SIGN_IN, FUNDING_SIGN_IN].map((path) => (
            <Route
              path={path}
              key={path}
              element={<TokenSignIn branding={branding} />}
            />
          ))}
          <Route path={SIGN_OUT} element={<SignOut />} />
          <Route path={BANNO_PLUGIN} element={<BannoPlugin />} />
          <Route
            path="/*"
            element={
              <div className="center_page">
                <div>
                  <h3>You are not registered to use this service</h3>
                  <h4>Please contact support for assistance</h4>
                </div>
              </div>
            }
          />
        </Routes>
      )}
    </>
  );
};

export default AppRouter;
